export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'DD-MM-YYYYTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const PROGRESS_PERCENTAGE={
  "0":1,
  "1":2,
  "2":3,
  "3":4,
  "4":5,
  "5":6,
  "6":7,
  "7":8,
  "8":9,
  "9":10,
  "100":10,
  "101":11,
  "102":12,
  "103":13,
  "104":14,
}
