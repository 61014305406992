import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { updateEntity, updateEntityProperty } from './accounts.reducer';

interface NetWorthAnnualProps {
  onFormComplete: (completed: boolean) => void;
}

export interface NetWorthAnnualRef {
  syncNetWorthAnnualWithServer: () => Promise<any>;
  saveNetWorthAnnualWithServer: () => Promise<any>;

}

const NetWorthAnnual: React.ForwardRefRenderFunction<NetWorthAnnualRef, NetWorthAnnualProps> = ({ onFormComplete }: NetWorthAnnualProps, ref) => {
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const dispatch = useAppDispatch();
  const [totalNetWorth, setTotalNetWorth] = useState<string>(mainAccountsEntity.totalNetWorth || '');
  const [liquidNetWorth, setLiquidNetWorth] = useState<string>(mainAccountsEntity.liquidNetWorth || '');
  const [totalNetIncome, setTotalNetIncome] = useState<string>(mainAccountsEntity.totalNetIncome || '');
  
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [liquidNetWortherrors, setliquidNetWorthErrors] = useState<{ [key: string]: string }>({});

  const [tradingExperience, setTradingExperience] = useState({
    years_of_trading: mainAccountsEntity?.tradingExperience?.years_of_trading || 0 ,
    trades_per_year: mainAccountsEntity?.tradingExperience?.trades_per_year || 0 ,
    knowledge_level: mainAccountsEntity?.tradingExperience?.knowledge_level || 'None',
  });


  useImperativeHandle(ref, () => ({
    syncNetWorthAnnualWithServer,
    saveNetWorthAnnualWithServer
  }));

  const syncNetWorthAnnualWithServer = (): Promise<any>=> {
    return dispatch(updateEntity({...mainAccountsEntity, last_level: 7}));
  };

  const saveNetWorthAnnualWithServer = (): Promise<any> => {
    if (mainAccountsEntity?.accountId && mainAccountsEntity.accountId !== '') {

      return dispatch(updateEntity({ ...mainAccountsEntity }));
    }
    }

  const formatCurrency = (amount: number | string) => {
    if (amount === '' || amount === undefined || amount === null) {
      return '';
    }

    if (isNaN(Number(amount))) {
      return '';
    }

    const parsedAmount = parseFloat(amount.toString());

    return parsedAmount.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/[^\d.]/g, '');
    const newErrors = { ...errors };
    const newLiquidNetWorthErrors = { ...liquidNetWortherrors };

    if (name === 'totalNetWorth') {
      setTotalNetWorth(formattedValue);
      const totalNetWorthNum = parseFloat(formattedValue);
      dispatch(updateEntityProperty({ totalNetWorth: formattedValue }));
    }
    else if (name === 'liquidNetWorth') {
      setLiquidNetWorth(formattedValue);
      const liquidNetWorthNum = parseFloat(formattedValue);
      const totalNetWorthNum = parseFloat(totalNetWorth);

      if (liquidNetWorthNum < 50000) {
        newLiquidNetWorthErrors.liquidNetWorth = 'Liquid Net Worth must be greater than or equal to €50,000.';
      } else {
        delete newLiquidNetWorthErrors.liquidNetWorth;
        dispatch(updateEntityProperty({ liquidNetWorth: formattedValue }));
      }
    } else if (name === 'totalNetIncome') {
      setTotalNetIncome(formattedValue);
      dispatch(updateEntityProperty({ totalNetIncome: formattedValue }));
    }

    setErrors(newErrors);
    setliquidNetWorthErrors(newLiquidNetWorthErrors);
  };

  const handleTradingExperience = (e: any) => {
    const { name, value } = e.target;
    setTradingExperience(prevTradingExperience => ({
      ...prevTradingExperience,
      [name]: value
    }));
    dispatch(updateEntityProperty({ tradingExperience: { ...mainAccountsEntity?.tradingExperience, [name]: value } }));
  }
  useEffect(() => {
     //  default values are sent in the initial payload
    dispatch(updateEntityProperty({ tradingExperience }));
  }, []);

  useEffect(() => {
    validateInputs();
  }, [totalNetWorth, liquidNetWorth, totalNetIncome,tradingExperience]);

  const validateInputs = () => {
    const totalNetWorthNum = parseFloat(totalNetWorth);
    const liquidNetWorthNum = parseFloat(liquidNetWorth);

    const isLiquidNetWorthValid = !isNaN(liquidNetWorthNum)  && liquidNetWorthNum >= 50000;
    const isTotalNetIncomeValid = totalNetIncome !== '';

    if (isLiquidNetWorthValid ) {
      onFormComplete(true);
    } else {
      onFormComplete(false);
    }
  };

  return (
    <div className='pb-3'>
      <Row>
        <Col md="12" className="form-heading-custom pt-3">
          Your Financial Information and Investment Experience
        </Col>
        <Col md="12" className='pt-4 pb-3'>
          <Card className='form-custom-card'>
            <CardBody>
              <Col md="12" className="from-sub-heading-custom">
              Your Net Worth and Annual Income
              </Col>
              <Col md="12" className="pt-3">
                We need this information to help us to determine if your Investment Amount is appropriate considering your overall financial
                situation
              </Col>
            </CardBody>
          </Card>
        </Col>
        
        {/* <Col md="12" className="pt-3">
          For joint applications, please provide the total for both parties
        </Col> */}
        <Col md="12 pt-3">
          <FormGroup>
            <Label>
              <div className='from-sub-heading-custom'>
                <span>*</span>
                Total Net Worth
              </div>
              
              <div className="account-sub-span-custom">
                Your total assets (e.g. property, savings, investments) less your total liabilities (e.g. mortgage, loans).
              </div>
            </Label>
            <Input
              type="tel"
              name="totalNetWorth"
              id="totalNetWorth"
              placeholder="€00,000"
              value={formatCurrency(totalNetWorth)}
              defaultValue={formatCurrency(mainAccountsEntity.totalNetWorth)}
              className="custom-from-input"
              inputMode="numeric"
              onChange={handleInputChange}
            />
            
          </FormGroup>
        </Col>
        <Col md="12" className='pt-3'>
          <FormGroup>
            <Label className="label-account-class">
            <div className='from-sub-heading-custom'><span>*</span>
              Liquid Net Worth
              </div>
              <div className="account-sub-span-custom">
                The part of your Total Net Worth which can be easily converted to cash if needed.
              </div>
            </Label>
            <Input
              type="tel"
              name="liquidNetWorth"
              id="liquidNetWorth"
              placeholder="€00,000"
              value={formatCurrency(liquidNetWorth)}
              defaultValue={formatCurrency(mainAccountsEntity.liquidNetWorth)}
              className="custom-from-input"
              inputMode="numeric"
              onChange={handleInputChange}
            />
            {liquidNetWortherrors.liquidNetWorth && (
              <span className="error-message" style={{ color: 'red' }}>
                {liquidNetWortherrors.liquidNetWorth}
              </span>
            )}
             {/* <br /> */}
            {/* <span className="account-sub-span-custom">Liquid Net Worth must be greater than equel to 5000 K.</span> */}
          </FormGroup>
        </Col>
        <Col md="12" className='pt-3'>
          <FormGroup>
            <Label className="label-account-class">
            <div className='from-sub-heading-custom'>
              <span>*</span>
              Total Net Income
              </div>
              <div className="account-sub-span-custom">
                Your total annual income from employment, savings, investments, property, etc., after tax.
              </div>
            </Label>
            <Input
              type="tel"
              name="totalNetIncome"
              id="totalNetIncome"
              placeholder="€00,000"
              value={formatCurrency(totalNetIncome)}
              defaultValue={formatCurrency(mainAccountsEntity.totalNetIncome)}
              className="custom-from-input"
              inputMode="numeric"
              onChange={handleInputChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Col md="12" className="under-border-div mt-3 mb-3"></Col>
          </FormGroup>
        </Col>
        <Col md="12" className="from-sub-heading-custom pt-3">
        Your Investment Experience 
        </Col>
        <Col md="12" className="account-sub-span-custom pt-3">
        Let us know if you have any Investment Experience. Don’t worry if you don’t. That’s why we are here. 
        </Col>
          <Col md="12" className='pt-3'>
            <Label className='label-custom-form-input'>* Investment Experience</Label>
        <Input
                type="select"
                name="years_of_trading"
                id="years_of_trading"
                className="custom-from-input"
                value={tradingExperience?.years_of_trading}
                onChange={e=>handleTradingExperience(e)}
              >
              <option value={0}>No Experience </option>
              <option value={1}>1-5 Years of Experience </option>
              <option value={2}>6-10 Years of Experience </option>
              <option value={3}> {">"}10 Years of Experience</option>
                </Input>
              </Col>
          <Col md="12 pt-3" >
          <Label className='label-custom-form-input'>* Trades per year</Label>
              <Input
                type="select"
                name="trades_per_year"
                id="trades_per_year"
                className="custom-from-input"
                value={tradingExperience?.trades_per_year}
                onChange={e =>handleTradingExperience(e)}
              >
              <option value={0}>No Trades </option>
              <option value={1}>1-10 Trades per Year </option>
              <option value={2}>11-25 Trades per Year  </option>
              <option value={3}> {">"}25 Trades per Year</option>
                </Input>
              </Col>
          <Col md="12 pt-3" >
            <Label className='label-custom-form-input'>* Investment Knowledge</Label>
              <Input
                type="select"
                name="knowledge_level"
                id="knowledge_level"
                className="custom-from-input"
                value={tradingExperience?.knowledge_level}
                onChange ={e =>handleTradingExperience(e)}
              >
              <option value={'None'}>No Knowledge </option>
              <option value={'Limited'}>Limited Knowledge </option>
              <option value={'Good'}>Good Knowledge </option>
              <option value={'Extensive'}>Extensive Knowledge</option>
                </Input>
              </Col>
        </Row>
    </div>
  );
};
export default forwardRef(NetWorthAnnual);
