
import * as React from 'react';
import { PencilFill } from 'react-bootstrap-icons';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import './account.scss';
import { getCountryList, getEntity, getState, updateEntity, updateEntityProperty } from './accounts.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getCustomerDetails } from '../customer/customer.reducer';
import sessionStorage from 'redux-persist/es/storage/session';

interface AccountSummaryProps {
  onFormComplete: (completed: boolean) => void;
}
export interface AccountSummaryRef {
  syncAccountSummaryWithServer: () => Promise<any>;
}
interface Country {
  code: string;
  name: string;
}
interface State {
  code: string;
  name: string;
}

const AccountSummary: React.ForwardRefRenderFunction<AccountSummaryRef, AccountSummaryProps> = ({ onFormComplete }: AccountSummaryProps, ref) => {
  const dispatch = useAppDispatch();

  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);
  const isother: any = sessionStorage.getItem('is_description');
  const [accountHolderType, setAccountHolderType] = useState(mainAccountsEntity.accountHolderType || 'Individual');
  const [isEditing, setIsEditing] = useState(false);
  const [stateList, setStateList] = useState<State[]>([]);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({ ...mainAccountsEntity, ...mainAccountsEntity.joint_details, ...mainAccountsEntity.employerAddress } || {});

  const [employerCountry, setEmployerCountry] = useState('');
  const [employerState, setEmployerState] = useState('');
  const [individualStateOrProvince, setIndividualStateOrProvince] = useState('');
  const [individualIssuedCountry, setIndividualIssuedCountry] = useState('');
  const [individualCountryOfBirth, setIndividualCountryOfBirth] = useState('');
  const [jointCountryOfBirth, setJointCountryOfBirth] = useState('');
  const [jointIssuedCountry, setJointIssuedCountry] = useState('');
  const [jointStateOrProvince, setJointStateOrProvince] = useState('');
  const [jointemployerCountry, setJointEmployerCountry] = useState('');
  const [jointemployerState, setJointEmployerState] = useState('');
  const [jointnewdependent, setJointNewDependent] = useState('');
  const accessToken = localStorage.getItem('jhi-authenticationToken');

  useEffect(() => {
    if (accessToken) {
      dispatch(getCountryList()).then((res) => {
        dispatch(getCustomerDetails());

        if (res.payload) {
          const countryData: Country[] = res.payload.map((item: any) => ({
            code: item.code,
            name: item.name,
          }));
          setCountryList(countryData);
        }
      });
    }
  }, [dispatch, accessToken]);

  useEffect(() => {
    dispatch(getState()).then((res) => {
      if (res.payload) {
        const stateData: State[] = res.payload.map((item: any) => ({
          code: item.code,
          name: item.name,
        }));
        setStateList(stateData);
      }
    });

  }, [dispatch]);

  const formatCurrency = (amount: number | string) => {
    if (amount === '') {
      return '';
    }

    if (isNaN(Number(amount))) {
      return '';
    }

    const parsedAmount = parseFloat(amount?.toString());

    return parsedAmount?.toLocaleString('en-EN', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
    });
  };
  useEffect(() => {
    const accountId = mainAccountsEntity?.toString() || '';

    if (accountId) {

      dispatch(getEntity(mainAccountsEntity?.accountId))
        .then((new_response: any) => {
          setEmployerCountry(new_response?.payload?.data?.employerAddress?.country);
          setEmployerState(new_response?.payload?.data?.employerAddress?.state);
          setIndividualStateOrProvince(new_response?.payload?.data?.state_or_province);
          setIndividualIssuedCountry(new_response?.payload?.data?.issued_country);
          setIndividualCountryOfBirth(new_response?.payload?.data?.birth_info?.countryOfBirth);
          setJointCountryOfBirth(new_response?.payload?.data?.joint_details?.jointcountryofbirth);
          setJointIssuedCountry(new_response?.payload?.data?.joint_details?.jointissuedcountry);
          setJointStateOrProvince(new_response?.payload?.data?.joint_details?.jointstateorprovince);
          setJointEmployerCountry(new_response?.payload?.data?.joint_details?.jointemployeraddress?.country);
          setJointEmployerState(new_response?.payload?.data?.joint_details?.jointemployeraddress?.state);
          setJointNewDependent(new_response?.payload?.data?.joint_details?.jointdependents);
        })
        .catch(error => {
          console.error('Failed to fetch account entity:', error);
        });
    }
  }, []);

  useImperativeHandle(ref, () => ({
    syncAccountSummaryWithServer
  }));

  const syncAccountSummaryWithServer = (): Promise<any> => {
    return dispatch(updateEntity({ ...mainAccountsEntity, last_level: 100 }));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleUpdateClick = () => {
    setIsEditing(false);
  };
  const [checkboxStates, setCheckboxStates] = useState({
    diffMailing: false,
    diffCitizenship: false,
    bornOutside: false,
    statement: false,
  });

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.target;
    dispatch(updateEntityProperty({ [inputName]: value }));
    inputValues[inputName] = value;
  };

  const experienceMapping = {
    0: 'No Experience',
    1: '1-5 Years of Experience',
    2: '6-10 Years of Experience',
    3: '>10 Years of Experience'
  };

  const tradingExperienceMapping = {
    0: 'No Trades ',
    1: '1-10 Trades per Year ',
    2: '11-25 Trades per Year',
    3: '>25 Trades per Year'
  };

  const formatDate = (dob) => {
    return new Date(dob).toLocaleDateString('en-GB');
  };
  
  return (
    <>
      <Row>
        {/* <Col md="12" className="pt-3 text-end">
          {isEditing ? (
            <Button onClick={handleUpdateClick} className="update-button-account-summary-submit">
              Update
            </Button>
          ) : (
            <PencilFill className="edit-button-style" onClick={handleEditClick} />
          )}
        </Col> */}
        <Col md="12" className="form-heading-custom pt-3 pb-3">
        My Starlight Smart Reserve Application Summary  
        </Col>

        <Col md="12" className="pt-3">
          <span className="strong">Please review the information you have entered. If you need to make any changes, please navigate back to the relevant form section to update the information.</span>
        </Col>
        <Col md="12" className="pt-3 form-heading-custom">
          Initial Requirements Check
        </Col>

        <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          You have confirmed that:
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        You must be able to meet all of these requirements to open an account
        </Col>

        <Col md="12" style={{ lineHeight: '2.5' }}>
        I am over 18
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        I will be the beneficial owner of the account
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        I am resident in Ireland
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        I am tax resident in ireland and have a PPS number
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        I have minimum lump sum of 50,000 available to invest and I have a minimum of 3 months living expenses covered from other funds
        </Col>
        <Col md="12" style={{ lineHeight: '2.5' }}>
        My investment objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit
        rate
        </Col>

        {/* Main Heading */}
        <Col md="12" className="pt-3 form-heading-custom" >
          <span className="strong">{mainAccountsEntity?.accountHolderType === "Joint" ?'Primary Applicant Details':' Applicant Details'}</span>
        </Col>

          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Title:</span>
          </Col>
          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderTitle}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">First Name:</span>
          </Col>
          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderName?.charAt(0).toUpperCase() + inputValues?.accountHolderName?.slice(1).toLowerCase()}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Last Name:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderSurname?.charAt(0).toUpperCase() + inputValues?.accountHolderSurname?.slice(1).toLowerCase()}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Middle Name:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
        {inputValues?.middle_name? inputValues.middle_name.charAt(0).toUpperCase() + inputValues.middle_name.slice(1).toLowerCase(): ''}
          </Col>
        <Col md="6" className='pt-3' style={{ lineHeight: '2.5' }}>
          <span className="strong" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
            Your Email:
          </span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderEmail}
          </Col> 
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5' }}>
          <span className="strong" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
            Phone Number:
          </span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderMobileNumber}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Marital Status:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderMaritalStatus}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Dependants:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderDependents}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          Your Account Type:
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.accountHolderType}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Your Investment Amount:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
        {formatCurrency(inputValues?.investmentAmount)}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">House Street Address:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.addressLine1}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Address Line 2:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.addressLine2}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">County:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.state_or_province}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Town / City:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.townOrCity}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Country:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.country}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Post Code:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.postcode}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">PPS Number:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.ppsNumber}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Passport Number:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.passport_number}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Country Issued:</span>
        </Col>
        <Col md="6"  className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.issued_country}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Passport Expiry Date:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
        {formatDate(inputValues.passport_expiry_date)}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Country of Birth:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
        {mainAccountsEntity?.birth_info?.countryOfBirth}
          </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
          <span className="strong">Country of Citizenship:</span>
        </Col>
        <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          {inputValues?.citizenship}
          </Col>
          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Are you also a tax resident in another country?</span>
                  </Col>
                  <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {!inputValues?.taxResidentInIreland ? 
                        'Yes'
                     : 
                        'No'
                    }
                  </Col>
                  {!inputValues?.taxResidentInIreland && (
                <><Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Other Tax Residence Details:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.othertaxresidence}
                    </Col></>
                  )}

<Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Employment Status:</span>
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {inputValues?.employment_status?.charAt(0).toUpperCase() + inputValues?.employment_status?.slice(1).toLowerCase()}
                </Col>

                {(inputValues?.employment_status === 'EMPLOYED' || inputValues?.employment_status === 'SELFEMPLOYED') && (
                  <>
                    <Col md="6"  className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Occupation:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.occupation}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Employer Name:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.employerName}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Employer Business:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.employerBusiness}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Country:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.country}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">County:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.state}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">City:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.city}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Post Code:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.postal_code}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Street:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {inputValues?.street}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Is the Employer a Publicly Listed Company?:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {mainAccountsEntity.isapubliclylistedcompany ? 'Yes' : 'No'}
                    </Col>
                    {mainAccountsEntity?.isapubliclylistedcompany && (
                    <>
                      <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        <span className="strong">Employer Company Stock Ticker Symbol:</span>
                      </Col>
                      <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {mainAccountsEntity?.employerCompany}
                      </Col>
                    </>
                  )}
                  </>
                )}

        {mainAccountsEntity?.accountHolderType === "Joint" && (<>

          <Col md="12" className="pt-3 form-heading-custom">
            <span className="strong">Secondary Applicant Details</span>
          </Col>

          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Title:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointnametitle"
              id="jointnametitle"
              className="summary-text-fields"
              defaultValue={mainAccountsEntity?.joint_details?.jointnametitle}
              value={inputValues.jointnametitle}
              onChange={e => handleValueChange(e)}
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            First Name:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointfirstname"
              id="jointfirstname"
              defaultValue={mainAccountsEntity?.joint_details?.jointfirstname}
              value={inputValues.jointfirstname}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Last Name:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointsurname"
              id="jointsurname"
              defaultValue={mainAccountsEntity?.joint_details?.jointsurname}
              value={inputValues.jointsurname}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Middle Name:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointmiddleName"
              id="jointmiddleName"
              required
              defaultValue={mainAccountsEntity?.joint_details?.jointmiddleName}
              value={inputValues.jointmiddleName}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Your Email:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="email"
              name="joint_username"
              required
              id="joint_username"
              defaultValue={mainAccountsEntity?.joint_details?.joint_username}
              value={inputValues.joint_username}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Phone Number:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="tel"
              name="jointmobilenumber"
              id="jointmobilenumber"
              defaultValue={mainAccountsEntity?.joint_details?.jointmobilenumber}
              value={inputValues.jointmobilenumber}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Marital Status:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointmaritalstatus"
              id="jointmaritalstatus"
              defaultValue={mainAccountsEntity?.joint_details?.jointmaritalstatus}
              value={inputValues.jointmaritalstatus}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Joint Dependants:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointdependents"
              id="jointdependents"
              // defaultValue={String(mainAccountsEntity?.joint_details?.jointdependents)}
              defaultValue={jointnewdependent}
              value={inputValues.jointdependents}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Your Account Type:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="accountHolderType"
              id="accountHolderType"
              value={accountHolderType}
              defaultValue={mainAccountsEntity.accountHolderType}
              onChange={handleValueChange}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Date of Birth:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointdob"
              id="jointdob"
              className="summary-text-fields"
              defaultValue={formatDate(mainAccountsEntity?.joint_details?.jointdob)}
              value={formatDate(inputValues.jointdob)}
              // onChange={handleAccountType}
              readOnly={!isEditing}
            />
          </Col>

          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            House Street Address:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointaddressline1"
              id="jointaddressline1"
              defaultValue={mainAccountsEntity?.joint_details?.jointaddressline1}
              value={inputValues.jointaddressline1}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Address Line 2:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointaddressline2"
              id="jointaddressline2"
              defaultValue={mainAccountsEntity?.joint_details?.jointaddressline2}
              value={inputValues.jointaddressline2}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            County:
          </Col>
          <Col md="12" className='summary-country-text-styles'>
            {!isEditing && <>
              {mainAccountsEntity?.joint_details?.jointstateorprovince}
            </>}
            {isEditing && <>
              <Input
                type="select"
                name="jointstateorprovince"
                id="jointstateorprovince"
                // defaultValue={mainAccountsEntity.jointstateorprovince}
                defaultValue={mainAccountsEntity?.joint_details?.jointstateorprovince}
                value={inputValues.jointstateorprovince}
                onChange={e => handleValueChange(e)}
                className="summary-text-fields"
              >
                {stateList && stateList.map((item, index) => (
                  <option key={index} defaultValue={item?.name}>{item.name}</option>
                ))}
              </Input>
            </>}

          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Town / City:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointtownorcity"
              id="jointtownorcity"
              defaultValue={mainAccountsEntity?.joint_details?.jointtownorcity}
              value={inputValues.jointtownorcity}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Country:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointcountry"
              id="jointcountry"
              defaultValue={mainAccountsEntity?.joint_details?.jointcountry}
              value={inputValues.jointcountry}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            >
              {countryList && countryList.map((item, index) => (
                <option key={index} value={item?.name}>{item.name}</option>
              ))}
            </Input>
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Post Code:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointpostcode"
              id="jointpostcode"
              defaultValue={mainAccountsEntity?.joint_details?.jointpostcode}
              value={inputValues.jointpostcode}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Secondary Applicant PPS Number:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointppsnumber"
              id="jointppsnumber"
              defaultValue={mainAccountsEntity?.joint_details?.jointppsnumber}
              value={inputValues.jointppsnumber}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Secondary Applicant Passport Number:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="dependendPassportNumber"
              id="dependendPassportNumber"
              defaultValue={mainAccountsEntity?.joint_details?.dependendPassportNumber}
              value={inputValues.dependendPassportNumber}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Country Issued:
          </Col>
          <Col md="12" className='summary-country-text-styles'>
            {!isEditing && <>
              {mainAccountsEntity?.joint_details?.jointissuedcountry}
            </>}
            {isEditing && <>
              <Input
                type="text"
                name="jointissuedcountry"
                id="jointissuedcountry"
                // defaultValue={mainAccountsEntity.issued_country}
                defaultValue={mainAccountsEntity?.joint_details?.jointissuedcountry}
                value={inputValues.jointissuedcountry}
                onChange={e => handleValueChange(e)}
                className="summary-text-fields"
              >
                {countryList && countryList.map((item, index) => (
                  <option key={index} defaultValue={item?.name}>{item.name}</option>
                ))}
              </Input>
            </>}
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Passport Expiry Date:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              name="jointpassport_expiry_date"
              type="date"
              data-cy="date of birth"
              defaultValue={mainAccountsEntity?.joint_details?.jointpassport_expiry_date}
              value={inputValues.jointpassport_expiry_date}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong">Country of Birth:</span>
          </Col>
          <Col md="12" className='summary-country-text-styles'>
            {!isEditing && <>
              {mainAccountsEntity?.joint_details?.jointcountryofbirth}
            </>}
            {isEditing && <>
              <Input
                type="select"
                name="jointcountryofbirth"
                id="jointcountryofbirth"
                className="summary-text-fields"
                defaultValue={mainAccountsEntity?.joint_details?.jointcountryofbirth}
                value={inputValues.jointcountryofbirth}
                onChange={e => handleValueChange(e)}
              >
                {countryList && countryList.map((item, index) => (
                  <option key={index} defaultValue={item?.name}>{item.name}</option>
                ))}
              </Input>
            </>}
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            <span className="strong">Country of Citizenship:</span>
          </Col>
          <Col md="12" className='summary-country-text-styles'>
              <Input
                type="text"
                name="jointcountryofcitizenship"
                id="jointcountryofcitizenship"
                className="summary-text-fields"
                defaultValue={mainAccountsEntity?.joint_details?.jointcountryofcitizenship}
                value={inputValues.jointcountryofcitizenship}
                onChange={e => handleValueChange(e)}
                readOnly={!isEditing}

              >
                {/* {countryList && countryList.map((item, index) => (
                  <option key={index} defaultValue={item?.name}>{item.name}</option>
                ))} */}
              </Input>
          </Col>

          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>


            <span className="strong">Are you also a tax resident in another country?:</span>


          </Col>
          <Col md="12" className='summary-country-text-styles'>

            {!isEditing && (
              <>

                {!mainAccountsEntity?.joint_details?.jointistaxresident ? (
                  <Col md="12" className='summary-country-text-styles'>
                    Yes
                  </Col>
                ) : (
                  <Col md="12" className='summary-country-text-styles'>
                    No
                  </Col>
                )}
              </>
            )}
            {isEditing && (
              <Input
                type="select"
                name="jointistaxresident"
                id="jointistaxresident"
                defaultValue={mainAccountsEntity?.joint_details?.jointistaxresident}
                value={inputValues.jointistaxresident}
                onChange={handleValueChange}
                className="summary-text-fields"
                readOnly

              >
                <option defaultValue="true">Yes</option>
                <option defaultValue="false">No</option>
              </Input>
            )}
          </Col>
          {!mainAccountsEntity?.joint_details?.jointistaxresident && (
            <Col md="12">
              <FormGroup>
                <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other Tax Residence Details:</span>
                </Col>
                <Col md="12" style={{ lineHeight: '2.5' }}>
                  <Input
                    type="text"
                    name="jointothertaxresidence"
                    id="jointothertaxresidence"
                    defaultValue={mainAccountsEntity?.joint_details?.jointothertaxresidence}
                    value={inputValues.jointothertaxresidence}
                    onChange={e => handleValueChange(e)}
                    className="summary-text-fields"
                    readOnly={!isEditing}
                  />
                </Col>
              </FormGroup>
            </Col>
          )}

          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Employment Status:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="jointemploymentstatus"
              id="jointemploymentstatus"
              defaultValue={mainAccountsEntity?.joint_details?.jointemploymentstatus?.charAt(0)?.toUpperCase() + mainAccountsEntity?.joint_details?.jointemploymentstatus?.slice(1)?.toLowerCase()}
              value={inputValues?.jointemploymentstatus?.charAt(0)?.toUpperCase() + inputValues?.jointemploymentstatus?.slice(1)?.toLowerCase()}
              onChange={handleValueChange}
              className="summary-text-fields"
              readOnly={!isEditing}
            />
          </Col>
          {(mainAccountsEntity?.joint_details?.jointemploymentstatus === 'EMPLOYED' || mainAccountsEntity?.joint_details?.jointemploymentstatus === 'SELFEMPLOYED') && (
            <>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Occupation:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="jointoccupation"
                  defaultValue={mainAccountsEntity?.joint_details?.jointoccupation}
                  value={inputValues.jointoccupation}
                  onChange={e => handleValueChange(e)}
                  id="jointoccupation"
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Employer Name:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="jointemployername"
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployername}
                  value={inputValues.jointemployername}
                  onChange={e => handleValueChange(e)}
                  id="jointemployername"
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Employer Business:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="jointemployerbusiness"
                  id="jointemployerbusiness"
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployerbusiness}
                  value={inputValues.jointemployerbusiness}
                  onChange={e => handleValueChange(e)}
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Employer Address
              </Col>

              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Country:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>

                <Input
                  type="text"
                  name="country"
                  // defaultValue={mainAccountsEntity?.jointemployeraddress?.country}
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployeraddress?.country}
                  value={inputValues.country}
                  onChange={e => handleValueChange(e)}
                  id="country"
                  className="summary-text-fields"
                  readOnly
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                County:
              </Col>
              <Col md="12" className='summary-country-text-styles'>
                {!isEditing && <>
                  {mainAccountsEntity?.joint_details?.jointemployeraddress?.state}
                </>}
                {isEditing && <>
                  <Input
                    type="text"
                    name="state"
                    // defaultValue={mainAccountsEntity?.jointemployeraddress?.state}
                    defaultValue={mainAccountsEntity?.joint_details?.jointemployeraddress?.state}
                    value={inputValues.state}
                    onChange={e => handleValueChange(e)}
                    id="state"
                    className="summary-text-fields"
                  >
                    {stateList && stateList.map((item, index) => (
                      <option key={index} defaultValue={item?.name}>{item.name}</option>
                    ))}
                  </Input>
                </>}
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                City:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="city"
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployeraddress?.city}
                  value={inputValues.city}
                  onChange={e => handleValueChange(e)}
                  id="city"
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Post Code:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="postal_code"
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployeraddress?.postal_code}
                  value={inputValues.postal_code}
                  onChange={e => handleValueChange(e)}
                  id="postal_code"
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Street:
              </Col>
              <Col md="12" style={{ lineHeight: '2.5' }}>
                <Input
                  type="text"
                  name="street"
                  defaultValue={mainAccountsEntity?.joint_details?.jointemployeraddress?.street}
                  value={inputValues.street}
                  onChange={e => handleValueChange(e)}
                  id="street"
                  className="summary-text-fields"
                  readOnly={!isEditing}
                />
              </Col>
              <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Is the Employer a Publicly Listed Company:
              </Col>
              <Col md="12" className='summary-country-text-styles'>
                {!isEditing && (
                  <>
                    {mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany === 'true' ? 'Yes' : 'No'}
                  </>
                )}
                {isEditing ? (
                  <>
                    <Input
                      type="select"
                      name="jointisapubliclylistedcompany"
                      defaultValue={mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany ? ("Yes") : "No"}
                      value={inputValues.jointisapubliclylistedcompany}
                      id="jointisapubliclylistedcompany"
                      onChange={e => handleValueChange(e)}
                      className="summary-text-fields"
                      readOnly={!isEditing}
                    >
                      <option defaultValue={'true'}>Yes</option>
                      <option defaultValue={'false'}>No</option>
                    </Input>
                  </>
                ) : (
                  <></>
                )}
              </Col>
              {mainAccountsEntity?.joint_details?.jointisapubliclylistedcompany === true && (
                <>
                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Employer Company Stock Ticker Symbol:
                  </Col>
                  <Col md="12" style={{ lineHeight: '2.5' }}>
                    <Input
                      type="text"
                      name="jointemployerCompany"
                      id="jointemployerCompany"
                      defaultValue={mainAccountsEntity?.joint_details?.jointemployerCompany}
                      value={inputValues.jointemployerCompany}
                      onChange={e => handleValueChange(e)}
                      className="summary-text-fields"
                      readOnly={!isEditing}
                    />
                  </Col>
                </>
              )}
            </>
          )}

        </>)}
        {checkboxStates?.bornOutside && (
          <>
            <Col md="12" className="summary-bold-text">
              I was Born Outside of Ireland:
            </Col>

            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="countryOfBirth"
                id="countryOfBirth"
                className="summary-text-fields"
                defaultValue={mainAccountsEntity?.birth_info?.countryOfBirth}
                value={inputValues.countryOfBirth}
                onChange={e => handleValueChange(e)}
                readOnly={!isEditing}
              />
            </Col>
          </>
        )}

        {mainAccountsEntity.isapubliclylistedcompany === 'true' ? (
          <>
            <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
              <span className="strong">Employer Company Stock Ticker Symbol:</span>
            </Col>
            <Col md="12" style={{ lineHeight: '2.5' }}>
              <Input
                type="text"
                name="employerCompany"
                id="employerCompany"
                defaultValue={mainAccountsEntity.employerCompany}
                value={inputValues.employerCompany}
                className="summary-text-fields"
                onChange={e => handleValueChange(e)}
                readOnly={!isEditing}
              />
            </Col>
          </>
        ) : null}

      </Row>
      <Col md="12" className="pt-3 form-heading-custom" >
      Anti-Money Laundering Requirements
      </Col>
      <Row>
      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Employment Income:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.investmentFromEmploymentIncome}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Property:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.investmentFromPropertyGains}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Gift:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.investmentFromGifts}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.investmentFromInvestmentGains}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.investmentFromOthers}
                </Col>
                {mainAccountsEntity?.investmentFromOthers &&(
                  <>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other Description:</span>
                </Col>
              
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {mainAccountsEntity?.inverstmentFromOthersInfo}
                </Col>
                </>
                )}
      </Row>

      <Col md="12" className="pt-3 form-heading-custom" >
      Your Financial Information and Investment Experience
      </Col>
      <Row>
      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Total Net Worth:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {formatCurrency(mainAccountsEntity?.totalNetWorth)}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Liquid Net Worth:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {formatCurrency(mainAccountsEntity?.liquidNetWorth)}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Total Net Income:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {formatCurrency(mainAccountsEntity?.totalNetIncome)}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment Experience:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {experienceMapping[mainAccountsEntity?.tradingExperience?.years_of_trading] || ''}
                </Col> 
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Trades per year:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {tradingExperienceMapping[mainAccountsEntity?.tradingExperience?.trades_per_year] || ''}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment Knowledge:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                {(mainAccountsEntity?.tradingExperience?.knowledge_level ? `${mainAccountsEntity.tradingExperience.knowledge_level} knowledge` : "")}
                </Col>
          </Row>
    
      <Col md="12" className="pt-3 form-heading-custom" >
        Politically Exposed Person (PEP)
      </Col>

      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>

      I confirm that I am not and have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body 
      :
      </Col>

      <Col md="12" className='summary-country-text-styles'>
        {!isEditing && (
          <>
            {mainAccountsEntity?.isPep === true ? (
              <Col md="12" className='summary-country-text-styles'>
                Confirmed
              </Col>
            ) : (
              <Col md="12" className='summary-country-text-styles'>
                Unable to Confirm for the Following Reasons
              </Col>
            )}
          </>
        )}

        {isEditing && (
          <Input
            type="select"
            name="isPep"
            id="isPep"
            className="summary-text-fields"
            checked={mainAccountsEntity?.isPep}
            value={inputValues.isPep}
            onChange={handleValueChange}
            readOnly

          >
            <option defaultValue="true">Confirmed</option>
            <option defaultValue="false">Unable to Confirm for the Following Reasons</option>
          </Input>
        )}
      </Col>


      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
        {isEditing && (
          <Input
            type="text"
            name="familymemberpepdetails"
            id="familymemberpepdetails"
            className="input-account-custom-bgnone"
            defaultValue={mainAccountsEntity?.familymemberpepdetails}
            value={inputValues.familymemberpepdetails}
            onChange={handleValueChange}
          />
        )}
          I confirm that no immediate family member have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body
                :
      </Col>
      <Col md="12" className='summary-country-text-styles'>
        {!isEditing && (
          <>
            {mainAccountsEntity?.isFamilyPep === true ? (
              <Col md="12" className='summary-country-text-styles'>
                Confirmed
              </Col>
            ) : (
              <Col md="12" className='summary-country-text-styles'>
                Unable to Confirm for the Following Reasons
              </Col>
            )}
          </>
        )}
        {isEditing && (

          <Input
            type="select"
            name="isFamilyPep"
            id="isFamilyPep"
            className="summary-text-fields"
            defaultValue={mainAccountsEntity?.isFamilyPep}
            value={inputValues.isFamilyPep}
            onChange={handleValueChange}
          >
            <option value="true">Confirmed</option>
            <option value="false">Unable to Confirm for the Following Reasons</option>
          </Input>

        )}
      </Col>
      {mainAccountsEntity.isPep === false || mainAccountsEntity.isFamilyPep === false ? (
        <>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Provided Details:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="familymemberpepdetails"
              id="familymemberpepdetails"
              defaultValue={mainAccountsEntity?.familymemberpepdetails}
              value={inputValues.familymemberpepdetails}
              className="summary-text-fields"
              onChange={handleValueChange}
              readOnly={!isEditing}
            />
          </Col>
        </>
      ) : null}

      <Col md="12" className="pt-3 form-heading-custom" >
      Regulated Employee Clients
      </Col>
      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
      Are you an Employee of a Publicly Traded Company:
      </Col>
      {mainAccountsEntity?.is_employee_of_public_company ? (
            <>
              <Col md="12" className='summary-country-text-styles'>
                Yes
              </Col>
              <Col md="12">
                <FormGroup>
                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Please provide details of the Publicly Traded Company and your involvement with it:</span>
                  </Col>
                  <Col md="12" style={{ lineHeight: '2.5' }}>
                    <Input
                      type="text"
                      name="othertaxresidence"
                      id="othertaxresidence"
                      className="summary-text-fields"
                      defaultValue={mainAccountsEntity.companyName}
                      value={inputValues.companyName}
                      onChange={handleValueChange}
                      readOnly={!isEditing}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </>
          ) : (
            <Col md="12" className='summary-country-text-styles'>
            No
            </Col>
          )}
      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
      Do you Control a Publicly Traded Company:
      </Col>
      {mainAccountsEntity?.controls_public_traded_company ? (
            <>
              <Col md="12" className='summary-country-text-styles'>
                Yes
              </Col>
              <Col md="12">
                <FormGroup>
                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Please provide details of the Publicly Traded Company and your involvement with it:</span>
                  </Col>
                  <Col md="12" style={{ lineHeight: '2.5' }}>
                    <Input
                      type="text"
                      name="othertaxresidence"
                      id="othertaxresidence"
                      className="summary-text-fields"
                      defaultValue={mainAccountsEntity.tradingName}
                      value={inputValues.tradingName}
                      onChange={handleValueChange}
                      readOnly={!isEditing}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </>
          ) : (
            <Col md="12" className='summary-country-text-styles'>
             No
            </Col>
          )}
      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
      I confirm I am not employed by a Financial Services Firm:
      </Col>

      {mainAccountsEntity?.employedfinancialservicefirm === "true" ? (
        <>
         <Col md="12" className='summary-country-text-styles'>
         Confirmed
              </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Compliance Officer Name:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="complianceofficername"
              id="complianceofficername"
              defaultValue={mainAccountsEntity?.complianceofficername}
              value={inputValues.complianceofficername}
              className="summary-text-fields"
              onChange={handleValueChange}
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Compliance Officer Contact Number:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="tel"
              name="complianceofficercontactnumber"
              id="complianceofficercontactnumber"
              defaultValue={mainAccountsEntity?.complianceofficercontactnumber}
              value={inputValues.complianceofficercontactnumber}
              onChange={e => handleValueChange(e)}
              className="summary-text-fields"
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Compliance Officer Email Address:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="complianceofficeremail"
              id="complianceofficeremail"
              defaultValue={mainAccountsEntity?.complianceofficeremail}
              value={inputValues.complianceofficeremail}
              className="summary-text-fields"
              onChange={handleValueChange}
              readOnly={!isEditing}
            />
          </Col>
        </>
      ) : (
        <Col md="12" className='summary-country-text-styles'>
          Not Confirmed
        </Col>
      )}

    <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
    I confirm that nobody in my immediate family is employed by a Financial Services Firm:
      </Col>
      {mainAccountsEntity?.isfamilyemployedfinancialservicefirm === true ? (
        <>
         <Col md="12" className='summary-country-text-styles'>
        Confirmed
              </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            Who Is Employed by the Financial Services Firm:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="whoemployedfinancialservicefirm"
              id="whoemployedfinancialservicefirm"
              defaultValue={mainAccountsEntity?.whoemployedfinancialservicefirm}
              value={inputValues.whoemployedfinancialservicefirm}
              className="summary-text-fields"
              onChange={handleValueChange}
              readOnly={!isEditing}
            />
          </Col>
          <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
            If a Family Member, Provide Relationship:
          </Col>
          <Col md="12" style={{ lineHeight: '2.5' }}>
            <Input
              type="text"
              name="familymemberrealtionship"
              id="familymemberrealtionship"
              defaultValue={mainAccountsEntity?.familymemberrealtionship}
              value={inputValues.familymemberrealtionship}
              className="summary-text-fields"
              onChange={handleValueChange}
              readOnly={!isEditing}
            />
          </Col>
        </>
      ) : (
        <Col md="12" className='summary-country-text-styles'>
         Not Confirmed
        </Col>
      )}

    </>
  );
};

export default forwardRef(AccountSummary);


