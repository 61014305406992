import React, { memo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './Toast.css';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { Button } from 'reactstrap';

interface ToastProps {
  visible?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  isAutoClose?: boolean;
  needCloseButton?: boolean;
  title?: string;
  description?: string;
  second_description?: string;
  third_description?: string;
  type?: 'success' | 'error' | 'custom' | 'warning';
  useWhiteOverlay?:boolean
}


const CommonToast: React.FC<ToastProps> = ({ visible, onClose, title, description, second_description,third_description, children, isAutoClose = true, needCloseButton = false, type = 'success',useWhiteOverlay = false  }) => {
  if (!visible) return null;

  useEffect(() => {
    isAutoClose && handleClose()
  }, [])

  const handleClose = () => {
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  return (
    <div className={`custom-alert-overlay ${'transparent-overlay'}`}>

   {/* <div className={`custom-alert-overlay`}> */}
   <div className={type === 'warning' ? 'custom-alert-modal-warning' : 'custom-alert-modal'}>
   {type === 'success' && (
          <>
            <FontAwesomeIcon icon={faCircleCheck} className="custom-alert-icon" />
            <div className="custom-alert-content pt-3">
              <div className="custom-alert-title">{title || 'Success'}</div>
              <div className="custom-alert-text">{description || 'Operation completed successfully.'}</div>
            </div>
            <div>
              <Button
                className="button-account-custom-submit"
                onClick={onClose}
              >
                Continue
              </Button>
              
            </div>
          </>
        )}
        {type === 'error' && (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} className="custom-alert-icon" />
            <div className="custom-alert-content pt-3">
              <div className="custom-alert-title">{title || 'Error'}</div>
              <div className="custom-alert-text">{description || 'An error occurred.'}</div>
            </div>
            <div>
              <Button
                className="button-account-custom-submit"
                onClick={onClose}
              >
                Continue
              </Button>
              
            </div>
          </>
        )}
        {type === 'warning' && (
          <>
          <div className="text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="custom-alert-icon" />
          </div>
            <div className="custom-alert-content pt-3">
              <div className="custom-alert-title">{title}</div>
              <div className="custom-alert-text">{description}</div>
              {second_description && <div className='custom-alert-text mt-3' >{second_description}</div>}
              {third_description && <div className='custom-alert-text mt-2' >{third_description}</div>}
            </div>
            <div>
              <Button
                className="button-account-custom-submit"
                onClick={onClose}
              >
                Continue
              </Button>
              
            </div>
          </>
        )}
        {type === 'custom' && (
          <>
            <div className="custom-alert-content">
              <div className="custom-alert-title">{title}</div>
              <div className="custom-alert-text">{description}</div>
            </div>
          </>
        )}
        {children}
        {!isAutoClose && !needCloseButton && <div className="custom-alert-footer">
          {/* <button className="custom-alert-button" onClick={onClose}>OK</button> */}
        </div>}
      </div>
    </div>
  );
};

export default memo(CommonToast);
