import { useAppDispatch, useAppSelector } from "app/config/store";
import {
  DepositBankList,
  depositConfig,
  getBankList,
  getIbkr,
  transaction,
} from "app/entities/accounts/accounts.reducer";
import React, { useEffect, useState } from "react";
import { Accordion, Card, CardBody } from "react-bootstrap";
import { Alert, Button, Col, Input, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import FundingInstructions from "../fundinginstructions/FundingInstructions";
import { Navigate, redirectDocument, useNavigate } from "react-router";
import CommonToast from "app/modules/components/CommonToast";

const Deposit = () => {
  const [depositDetails, setDepositDetails] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState({
    account_id: "",
    ibkr_account_number: "",
    amount: 0,
    transaction_type: "CREDIT",
    currency: "",
    bank: "",
  });
  const [toastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState<'success' | 'error'>('success');
  const [ErrorMessage, setErrorMessage] = useState('');
  const [completeDocumentation, setCompleteDocumentation] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { last_level } = useAppSelector(state => state.accounts.entity);

  const dispatch = useAppDispatch();
  const navigate=useNavigate();

  useEffect(() => {
    dispatch(depositConfig()).then((response: any) => {
      setDepositDetails(response?.payload);
   
    });
  }, []);
  // useEffect(() => {
  //   dispatch(getIbkr()).then((response: any) => {
  //     if (response?.payload) {
  //       setTransactionDetails((prevState) => ({
  //         ...prevState,
  //         account_id: response?.payload?.sw_account_id,
  //         ibkr_account_number:response?.payload?.ibkr_account_number
  //       }));
  //     }
  //   });
  // }, []);

  useEffect(() => {
    const fetchIbkrData = async () => {
      setIsLoading(true); // Start the loader
      try {
        const response = await dispatch(getIbkr());
        if (response?.payload) {
          setTransactionDetails((prevState) => ({
            ...prevState,
            account_id: response?.payload?.sw_account_id,
            ibkr_account_number: response?.payload?.ibkr_account_number
          }));
        }
      } catch (error) {
        // Handle any errors
        console.error('Failed to fetch IBKR data:', error);
      } finally {
        setIsLoading(false); // Stop the loader
      }
    };
  
    fetchIbkrData();
  }, [dispatch]);
  


  useEffect(() => {
    dispatch(DepositBankList()).then((res: any) => {
      if (res.payload) {
        setBankList(res?.payload);
      }
    });
  }, []);


  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [investmentAmount, setInvestmentAmount] = useState<string>('');


  const handleSave = () => {
    setOpen(true);
  };
  const toggle = () => {
    setOpen(!open);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name ==='amount' && value<0){
     setErrorMessage('value cannot be negative')
    }else if(name ==='amount' && value>=0){
      setErrorMessage('')
    }

    setTransactionDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formatCurrency = (amount: number): string => {
    return amount.toLocaleString('en-EN', {
      minimumFractionDigits: 0.00,
    });
  };

  const handleInvestmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove non-numeric and non-decimal characters for parsing
    const inputVal = e.target.value.replace(/[^\d.]/g, ''); 
    const numericValue = parseFloat(inputVal);
  
    if (!isNaN(numericValue)) {
      // Update state as a number
      setTransactionDetails(prevState => ({
        ...prevState,
        amount: numericValue,
      }));
    } else {
      // Handle empty input
      setTransactionDetails(prevState => ({
        ...prevState,
        amount: 0,
      }));
    }
  };

  const handleDeposit = async() => {
    try{

      const  Depositbank=bankList?.find((obj)=>obj?.link===transactionDetails?.bank)?.name
      const payload = {
        account_id: transactionDetails?.account_id || null,
        ibkr_account_number: transactionDetails?.ibkr_account_number || null,
        amount: transactionDetails?.amount,
        transaction_type: transactionDetails?.transaction_type,
        currency: transactionDetails?.currency,
        bank: Depositbank,
      };
      const response:any=await dispatch(transaction(payload)); 
      if (response?.payload?.id){
        setOpen(true);
        window.open(`${transactionDetails.bank}`, '_blank');
      }else{
        setOpen(false)
        setToastType('error');
        setToastMessage('Deposit  failed')
        setToastVisible(true);
      }
    }catch(error){
      setToastVisible(true);
      setToastMessage('Deposit  failed') 
    }

   
  };
  return (
    <div className="col-12 pb-5">
           <CommonToast
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        title={toastType === 'success' ? 'Success' : 'Error'}
        description={toastMessage}
        type={toastType}
        isAutoClose={true}
      />
      {isLoading ? (
      <div className="loader-container dashboard-loader-container">
        <div className="spinner-border text-primary custom-spinner" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      transactionDetails.ibkr_account_number ? (
      <>
      <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row pt-1">
              <div className="col dashboard-card-sub-heading align-content-center">
                Deposit Amount:
              </div>
              <div className="col-8">
                <Input
                  type="text"
                  className="dashboard-select-style"
                  name="amount"
                  value={transactionDetails.amount !== 0 ? formatCurrency(transactionDetails.amount) : ''} 
                  onChange={handleInvestmentChange}
                  placeholder="0.00" />
                {ErrorMessage && (<p className="sub-content-dashboard-danger">{ErrorMessage}</p>)}

              </div>

            </div>
            <div className="row pt-3">
              <div className="col dashboard-card-sub-heading align-content-center">
                Currency:
              </div>
              <div className="col-8">
                <Input
                  type="select"
                  className="dashboard-select-style"
                  name="currency"
                  onChange={handleInputChange}
                  value={transactionDetails?.currency}
                >
                  <option value="">Select..</option>
                  {Array.isArray(depositDetails) &&
                    depositDetails?.map((obj, index) => (
                      <option value={obj?.currency}>{obj?.currency}</option>
                    ))}
                </Input>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col dashboard-card-sub-heading align-content-center">
                My Bank:
              </div>
              <div className="col-8">
                <Input
                  type="select"
                  name="bank"
                  onChange={handleInputChange}
                  value={transactionDetails?.bank}
                  className="dashboard-select-style"
                >
                  <option value="">Select..</option>
                  {Array.isArray(bankList) &&
                    bankList?.map((bank, index) => (
                      <option value={bank.link} key={index}>
                        {bank?.name}
                      </option>
                    ))}
                </Input>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col dashboard-card-sub-heading ">
                Bank not listed?
              </div>
              <div className="col-12 sub-content-dashboard">
                If your bank is not yet listed in our system, please go your banks online banking portal and deposit funds in the normal way.
              </div>
            </div>
            <div className="row pt-5">
              <div className="col">
                <Button
                  className="button-account-custom-submit"
                  onClick={handleSave}
                  disabled={!transactionDetails.amount || !transactionDetails.bank || !transactionDetails.currency || !!ErrorMessage}
                >
                  Deposit
                </Button>
              </div>
            </div>
          </div>
        </div><div className="pt-5 accordion-div-dashboard">
            <Card className=" dashboard-custom-card pt-3">
              <CardBody>
                <div className="dashboard-card-heading">Funding Instructions</div>
                <Accordion alwaysOpen>
                  {Array.isArray(depositDetails) &&
                    depositDetails?.map((bank, index) => (
                      <Accordion.Item eventKey={bank?.currency}>
                        <Accordion.Header>
                          <div className="dashboard-card-sub-heading d-flex ">
                            <div>
                              <img src={bank?.subtitle} alt="" className="img-flag-bank" />
                            </div>
                            <div className="img-div-flag-bank">
                              {bank?.title} Funding Instructions
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <FundingInstructions depositDetails={bank} ibkr={null} modalturetochangetext={false} />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </CardBody>
            </Card>
          </div><Modal returnFocusAfterClose={focusAfterClose} isOpen={open} className="modal-deposit-dashboard">
            <ModalBody className="p-3">
              <div className="dashboard-card-heading text-center pt-2 pb-3">Depositing Funds</div>
              <FundingInstructions
                depositDetails={depositDetails?.find(
                  (obj) => obj?.currency === transactionDetails?.currency
                )}
                ibkr={transactionDetails}
                modalturetochangetext={true} />
              <div className="row pt-4">
                <div className="col">
                  <Button className="button-account-custom" onClick={toggle}>
                    Cancel
                  </Button>
                </div>
                <div className="col">
                  <Button
                    className="button-account-custom-submit"
                    onClick={handleDeposit}
                    disabled={!transactionDetails.amount || !transactionDetails.bank || !transactionDetails.currency}
                  >
                    Deposit Funds
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
      </>

          ):(
            last_level > 1 ? (
                <></>
            ):(
            <Row className='justify-content-center'>
               <Card className='col-md-8 form-custom-card'>
               <CardBody>
      <Col md="12" className='sub-content-dashboard'>
      {/* <Alert className="mt-3 alertclrr" isOpen={completeDocumentation} toggle={() => setCompleteDocumentation(false)}>
                Your application has been successfully submitted and is currently under review by our admin team. Once approved, it will be forwarded to IBKR for final approval, which may take an additional 2-3 days. You’ll receive an update via email once there’s any change in status. Thank you for your patience!        </Alert> */}
                 <div className="dashboard-card-sub-heading">Your Starlight Smart Account application is currently under review.</div>
              <div className='pt-3'>We will be in touch by email if we need anything else and will let you know once your account has been approved.</div>
              <div className='pt-3'>Thank you for your patience!</div>
              <div className='pt-3 dashboard-card-sub-heading'>The Starlight Wealth Team</div>
            </Col>
            </CardBody>
            </Card>
          </Row>
          )))}
    </div>
  );
};

export default Deposit;
