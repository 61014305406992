import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'

const Welcome = () => {
  return (
      <Row>
        <Col md="12" className="form-heading-custom pt-3">
           Welcome to Starlight Wealth
        </Col>
          <div className='pt-3'>
          <Card className=' form-custom-card'>
              <CardBody>
                  <div className='dashboard-card-sub-heading'>
                    Registration Steps Overview
                  </div>
                  <div className='pt-3'>
                      To help you prepare for the upcoming registration process,
                      we’ll need to verify your identity for regulatory purposes. Here’s a quick list of items you’ll need to gather and upload during this process:
                  </div>
                  <div className='pt-3'>
                    <b>Proof of address</b>
                  </div>
                  <div className='pt-3'>
                      <b>PPS Number</b>
                  </div>
                  <div className='pt-3'>
                      <b>Passport</b>
                  </div>
                  <div className='pt-3'>
                      Don’t worry if you can’t complete the form all at once.
                      You can use the "Save" option to finish it later at your convenience.
                      Come back at any time, we'll save your progress as you go.
                  </div>
                  <div className='pt-3'>
                      If you have any questions, please reach out to us at:
                  </div>
                  <div className='pt-3'>
                      <a
                          href="mailto:Starlightnewaccounts@starlightwealth.ie"
                          className="modal-text pt-2"
                          style={{ color: '#607980' }}
                      >
                          starlightnewaccounts@starlightwealth.ie
                      </a>
                  </div>
              </CardBody>
          </Card>
        </div>
          
    </Row>
  )
}

export default Welcome