import * as React from 'react';
import { Button, Col, Row,Input,FormGroup,Label} from 'reactstrap';
import './account.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAdminAccounts } from '../admin-profile/admin.reduce';
import { useReactToPrint } from 'react-to-print';


const ViewDocument = () => {
  const { accountId } = useParams();
  const getData = useAppSelector(state => state.accounts.entity);
  const mainAccountsEntity = useAppSelector(state => state.accounts.entity);

  const [customerId,setCustomerId]=useState('')
  const navigate = useNavigate()

  const handleSoSApplication = (accountId) => {
    window.open(`/sos/${accountId}`, '_blank');
  };

  const handleSeconddocApplication = (accountId) => {
    window.open(`/second-doc/${accountId}`, '_blank');
  };
  const handleW8benPrimaryApplication = (accountId) => {
    window.open(`/W-8BENPrimary/${accountId}`, '_blank');
  };
  const handleW8benSecondaryApplication = (accountId) => {
    window.open(`/W-8BENSecondary/${accountId}`, '_blank');
  };
  const handleIbApplication = (accountId) => {
    window.open(`/Ineractive-Broker/${accountId}`, '_blank');
  };
  const handleCompanySosApplication = (accountId) => {
    window.open(`/company-sos/${accountId}`, '_blank');
  };
  const handleCompanyPortfolioApplication = (accountId) => {
    window.open(`/company-portfolio/${accountId}`, '_blank');
  };


  const pageStyle = `
 
  @media print {
    body {
      font-size: 13px;
    }

    .no-print {
      display: none;
    }

    .new-form-bold-text,
    .new-form-italic-text,
    .new-form-underline-text {
      font-size: 13px;
      font-weight: bold;
      color: black;
    }

    .new-form-heading-text {
      font-weight: bold;
      font-size: 25px;
      color: black;
    }

    .new-form-light-text {
      font-weight: 300;
      font-size: 13px;
      color: black;
    }

    .new-form-very-light-text {
      font-weight: 300;
      font-size: 12px;
      color: black;
    }

    .new-form-italic-text {
      font-style: italic;
    }

    .bordered-table {
      width: 100%;
      border-collapse: collapse;
    }
    .bordered-table th,
    .bordered-table td {
      border: 1px solid black;
      padding: 8px;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .bordered-table th {
      background-color: #f2f2f2;
      font-weight: 300;
      font-size: 13px;
      color: black;
    }
    .new-form-table-row-one {
      background: #607980;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
    .new-form-table-row-two {
      background-color: #d5d8da;
      color: black;
      font-weight: 300;
      font-size: 13px;
    }
    .textfield-style {
      padding: 0.5rem 0.75rem;
      border: 1px solid #f1eded;
      border-radius: 0.25rem;
      background-color: #fff;
      font-size: 14px;
      color: #333;
      box-shadow: 0px 0.5px #c8c6c6;
    }
    .form-three-border {
      border-right: 1px solid black;
    }

    .form-row-border {
      border-bottom: 1px solid black;
    }

    .form-three-head {
      align-content: center;
    }
    .small-text {
      font-size: small;
    }
    .small-text-bold {
      font-size: small;
      font-weight: bolder;
    }
    .instruction-container-main {
      color: #000000;
      font-weight: 400;
    }
    .instruction-container-top-text {
      display: flex;
      justify-content: space-between;
      font-size: small;
      font-weight: bolder;
    }
    .instructions-text-container {
      font-size: small;
    }
    .instruction-container-text {
      display: flex;
      justify-content: space-between;
      font-size: small;
      color: #000000;
    }
    .instruction-container {
      border: none;
      border-bottom: 1px black solid;
    }
    .instruction-container-note-box {
      font-size: small;
    }
    .instruction-container-note-box-head {
      font-size: small;
      font-weight: bold;
    }
  }

`;
  const componentRef = React.useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'W-8BEN',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    pageStyle,
    removeAfterPrint: true,
  });


  const dispatch = useAppDispatch();
  const [applicationData, setApplicationData] = useState<any>({});


  useEffect(() => {
    if (accountId) {
      dispatch(getAdminAccounts({ accountId }))
        .then((response: any) => {
          if (response.payload.data) {
            setApplicationData(response.payload.data);
            setCustomerId(response.payload.data.customerId);
            
          }
        })
        .catch((error) => {
        });
    }
  }, [accountId]);

  useEffect(() => {
    if (customerId) {
      localStorage.setItem('customerId', customerId);
    }
  }, [customerId]);
  


  const handleBack = () => {
    navigate("/admin-profile?tab=customeAccountDetailspending")


  }
  const allRoles = new Set();
(applicationData?.company_details?.authorized_person_details || []).forEach(person => {
  (person.role || []).forEach(role => allRoles.add(role));
});

const experienceMapping = {
  0: 'No Experience',
  1: '1-5 Years of Experience',
  2: '6-10 Years of Experience',
  3: '>10 Years of Experience'
};

const tradingExperienceMapping = {
  0: 'No Trades ',
  1: '1-10 Trades per Year ',
  2: '11-25 Trades per Year',
  3: '>25 Trades per Year'
};

  return (
    <>
      <div className="form-container pt-5  " ref={componentRef}>
        <div style={{ backgroundColor: "#fff", color: "black", padding: "30px", fontSize: "15px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "auto" }}>
          <Row>
            <Row className="justify-content-between align-items-center no-print">
              <Col>
                <Button as={Link} onClick={handleBack} className="print_btn_w8 mb-3">
                  &#x3c; Back
                </Button>
              </Col>
              <Col className="text-end">
                <Button className="print_btn_w8 mb-5" onClick={handlePrint}>
                  Print
                </Button>
              </Col>
            </Row>
            <br />
            <Col md="12" className="form-firstrow-account-head-custom pt-3 pb-3 text-center">
              Application
            </Col>
            {applicationData?.accountHolderType?.toLowerCase() === 'organization' && (
              <>
               <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          Initial Suitability Check
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          You have confirmed that the Company:
          <br />
          Agrees to the Starlight Wealth Terms of Business, Privacy Policy, and Remuneration Policy

        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
          Is registered in Ireland

        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Is tax resident in Ireland

        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Will be the beneficial owner of the account

        </Col>

        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Has a lump sum available to invest
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Has a minimum of 3 months of operating expenses covered from other funds
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Investment Objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit rate
        </Col>

        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
          Will only hold the company’s proprietary assets in the account and will not hold funds of its clients or passive investors
        </Col>
                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong">Company Details</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Company Name:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.company_name}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Company Registration Number:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.registration_number}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Phone Number:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.accountHolderMobileNumber}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment Amount:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {`€${applicationData?.investmentAmount}`}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Country:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.country}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">County:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.state_or_province || "Galway"}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Town / City:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.townOrCity}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Post Code:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.postcode}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Street:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.street_or_province}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Description Of Business Activities:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.description_of_business_activities}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Legal Entity Identifier (LEI):</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.lei}
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">  If your company does not have an LEI,&nbsp; please indicate if you wish interactive Brokers to apply for an LEI on your behalf{' '}:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.is_lei? "Yes" : "No" }
                </Col>
                

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Tax Residency:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.tax_residency ? "Yes" : "No"}
                </Col>

                {applicationData?.company_details?.tax_residency && (
                  <>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Company Tax Registration Number:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                      {applicationData?.company_details?.tax_registration_number}
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">FATCA Classification:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                      {applicationData?.company_details?.fatca_classification}
                    </Col>
                  </>
                )}
                {applicationData?.company_details?.authorized_person_details?.map((obj: any, index: number) => {
                  return (
                    <>
                      <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>

                        <span className="strong">Company Authorised Person Details</span>
                      </Col>
                      <div key={index}>

                      <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                          <span className="strong">Key Person {index + 1}</span>
                        </Col>
                        <Row>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Name:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.firstname}&nbsp;{obj?.lastname}
                          </Col>

                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Role in the Company:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                          {['AUTHORIZED OFFICER', 'APPROVAL AUTHORITY', 'DIRECTOR', 'BENEFICIAL OWNER']?.map((possibleRole, i) => (
                            <FormGroup check key={i}>
                              <Label className="label-account-class" check>
                                <Input
                                  type="checkbox"
                                  readOnly={true}
                                  disabled
                                  className="input-account-custom-bgnone"
                                  checked={
                                    possibleRole === 'AUTHORIZED OFFICER'
                                      ? applicationData?.company_details?.authorized_person_details?.[index]?.role.includes('AUTHORIZED OFFICER')
                                      : possibleRole === 'BENEFICIAL OWNER'
                                      ? applicationData?.company_details?.authorized_person_details?.[index]?.role.includes('BENEFICIAL OWNER') ||
                                        applicationData?.company_details?.authorized_person_details?.[index]?.role.includes('BENIFICIAL OWNERS')
                                      : applicationData?.company_details?.authorized_person_details?.[index]?.role.includes(possibleRole)
                                  }
                                />{' '}
                                {possibleRole === 'AUTHORIZED OFFICER' ? 'COMPANY AUTHORISED PERSON' : possibleRole}
                              </Label>
                            </FormGroup>
                          ))}





                          </Col>


                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Company Email Address:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.company_email_address}
                          </Col>

                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Mobile Number:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.mobile_number}
                          </Col>
                         
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Date Of Birth:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.date_of_bith}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Country of Birth:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {/* {obj?.country_of_birth_and_citizen} */}
                            Ireland
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Country of Tax Residency:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.country_of_tax_residency}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">PPS Number:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.pps_number}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Passport Number:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.passport_number}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Passport Expiry Date:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.passport_expiry_date}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Country Of Citizenship:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.citizenship}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Owns greater than 15% of the Company?:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.owns_more_25_of_company === true ? "Yes" : "No"}
                          </Col>
                          {obj?.owns_more_25_of_company === true &&
                            <>
                              <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "bold" }}>
                              <span className="strong"> Source of personal wealth</span>
                              </Col>
                              <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                                {obj?.source_of_person_wealth}
                              </Col>
                            </>
                          }
                           <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">House Street Address:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.home_address}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Address Line 2:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.address?.street}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Town/City:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.address?.city}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">County:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.address?.state}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Country:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.address?.country}
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                            <span className="strong">Post Code:</span>
                          </Col>
                          <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                            {obj?.address?.postal_code}
                          </Col>
                        </Row>

                      </div>
                    </>
                  );
                })}



                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong">Company Financial</span>
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Total Net Worth:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {`€${applicationData?.totalNetWorth}`}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Liquid Net Worth:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {`€${applicationData?.liquidNetWorth}`}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Annual Net Income:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {`€${applicationData?.totalNetIncome}`}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Does your company generate 10% or more of its revenue outside of Ireland?:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.revenue_10_other_country === true ? "Yes" : "No"}
                </Col>
                {applicationData?.company_details?.revenue_10_other_country === true &&
                  <>

                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">List all countries outside Ireland where the company generates 10% or more of its revenue:</span>
                    </Col>
                    <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                      {applicationData?.company_details?.countries_revenue}
                    </Col>
                  </>
                }


                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Business Revenue %:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.business_revenue}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment / Funding Proceeds %:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.investment_funding}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other %:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.investmentFromOthers}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other (provide details):</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.company_details?.other_provide_details}
                </Col>

                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  Primary Applicant Signature
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  Statement of Suitability:
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                  {JSON.stringify(applicationData?.
                    account_signature_response?.statement_of_suitability?.primary_sign
                  )}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                Starlight Smart Reserve Model Portfolio Description:
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                  {JSON.stringify(applicationData?.
                    account_signature_response?.smart_reserve_portfolio?.primary_sign
                  )}
                </Col>

                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleCompanySosApplication(accountId); }}>Statement of Suitability</a>
                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleCompanyPortfolioApplication(accountId); }}>Starlight Smart Reserve Model Portfolio Description</a>


              </>
            )}








            {applicationData?.accountHolderType?.toLowerCase() !== 'organization' && (
              <>
               <Col md="12" className="pt-3 form-firstrow-account-head-custom">
          Initial Requirements Check
        </Col>

        <Col md="12" className="pt-3" style={{ fontSize: 'larger', fontWeight: 'bold' }}>
          You have confirmed that:
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        You must be able to meet all of these requirements to open an account
        </Col>

        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        I am over 18
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        I will be the beneficial owner of the account
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        I am resident in Ireland
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        I am tax resident in ireland and have a PPS number
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        I have minimum lump sum of 50,000 available to invest and I have a minimum of 3 months living expenses covered from other funds
        </Col>
        <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300"  }}>
        My investment objective is to preserve capital and generate a return that reflects the European Central Bank (ECB) deposit
        rate
        </Col>
                {/* Main Heading */}
                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong"> {applicationData?.accountHolderType === "Individual"
                ? 'Applicant Details'
                : applicationData?.accountHolderType === "Organization"
    ? 'Company Details'
    : 'Primary Applicant Details'}</span>
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Title:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData.accountHolderTitle}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">First Name:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.accountHolderName?.charAt(0).toUpperCase() + applicationData?.accountHolderName?.slice(1).toLowerCase()}
                </Col>

                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Last Name:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.accountHolderSurname?.charAt(0).toUpperCase() + applicationData?.accountHolderSurname?.slice(1).toLowerCase()}
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Middle Name:</span>
                </Col>
                <Col md="6" className="pt-3" style={{ lineHeight: '2.5', fontSize: 'larger', color: "black", fontWeight: "300" }}>
                  {applicationData?.middle_name? applicationData?.middle_name?.charAt(0).toUpperCase() + applicationData?.middle_name?.slice(1).toLowerCase(): ''}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Your Email:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.accountHolderEmail}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Phone Number:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.accountHolderMobileNumber}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Marital Status:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.accountHolderMaritalStatus}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Dependant:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.accountHolderDependents}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Your Account Type:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.accountHolderType}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Your Investment Amount:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {`€${applicationData.investmentAmount}`}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">House Street Address:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.addressLine1}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Address Line 2:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300", }}>
                  {applicationData.addressLine2}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">County:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.state_or_province}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Town / City:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.townOrCity}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Country:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.country}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Post Code:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.postcode}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">PPS Number:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.ppsNumber}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Country Issued:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.issued_country}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Passport Number:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData.passport_number}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Passport Expiry Date:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.passport_expiry_date}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Country of Birth:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.birth_info?.countryOfBirth}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Country of Citizenship:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.citizenship}
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Are you also a tax resident in another country?</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {!applicationData?.taxResidentInIreland ? 
                        'Yes'
                     : 
                        'No'
                    }
                  </Col>
                  {!applicationData?.taxResidentInIreland && (
                <><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Other Tax Residence Details:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.othertaxresidence}
                    </Col></>
                  )}

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Employment Status:</span>
                </Col>

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.employment_status?.charAt(0).toUpperCase() + applicationData?.employment_status?.slice(1).toLowerCase()
}
                </Col>

                {(applicationData?.employment_status === 'EMPLOYED' || applicationData?.employment_status === 'SELFEMPLOYED') && (
                  <>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Occupation:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.occupation}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Employer Name:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerName}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Employer Business:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerBusiness}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Country:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerAddress?.country}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">County:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerAddress?.state}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">City:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerAddress?.city}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Post Code:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerAddress?.postal_code}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Street:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.employerAddress?.street}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Is the Employer a Publicly Listed Company?:</span>
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.isapubliclylistedcompany === true ? 'Yes' : 'No'}
                    </Col>
                  </>
                )}

                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">I confirm I am not employed by a Financial Services Firm?:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                 {applicationData?.employedfinancialservicefirm === "true" ? "Yes" : "No"}
                </Col>


                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Who Is Employed by the Financial Services Firm?:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.whoemployedfinancialservicefirm}
                </Col>
                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong">Source of Wealth</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Employment Income:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.investmentFromEmploymentIncome}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Property:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.investmentFromPropertyGains}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Gift:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.investmentFromGifts}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.investmentFromInvestmentGains}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.investmentFromOthers}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Other Description:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {applicationData?.inverstmentFromOthersInfo}
                </Col>
                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong">Your Financial Information and Investment Experience</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Total Net Worth:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {`€${applicationData?.totalNetWorth}`}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Liquid Net Worth:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {`€${applicationData?.liquidNetWorth}`}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Total Net Income:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {`€${applicationData?.totalNetIncome}`}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment Experience:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {experienceMapping[applicationData?.tradingExperience?.years_of_trading] || ''}
                </Col> 
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Trades per year:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                  {tradingExperienceMapping[applicationData?.tradingExperience?.trades_per_year] || ''}
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  <span className="strong">Investment Knowledge:</span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                {(applicationData?.tradingExperience?.knowledge_level ? `${applicationData.tradingExperience.knowledge_level} knowledge` : "")}
                </Col>

                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong"> Politically Exposed Person (PEP) </span>
                </Col>
                <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">I confirm that I am not and have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body: </span>
                  </Col>
                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.isPep ? 
                        'Confirmed'
                     : 
                        'Unable to Confirm for the Following Reasons'
                    }
                  </Col>

                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">I confirm that no immediate family member have never been entrusted with a prominent public function in Ireland or abroad, whether as a politician or as a senior executive of a government body: </span>
                  </Col>
                  <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.isFamilyPep ? 
                        'Confirmed'
                     : 
                        'Unable to Confirm for the Following Reasons'
                    }
                  </Col>
      
                  {!(applicationData?.isPep && applicationData?.isFamilyPep) && (
                    <>
                      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Provided Details:
                      </Col>
                      <Col md="12" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: '300' }}>
                        {applicationData?.familymemberpepdetails}
                      </Col>
                    </>
                  )}


                <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  <span className="strong">Regulated Employee Clients </span>
                </Col>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Are you an Employee of a Publicly Traded Company:</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.is_employee_of_public_company ? 
                        'Yes'
                     : 
                        'No'
                    }
                  </Col>
                  {applicationData?.is_employee_of_public_company && (
                <><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Please provide details of the Publicly Traded Company and your involvement with it:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.companyName}
                    </Col></>
                  )}
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong"> Do you Control a Publicly Traded Company:</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.controls_public_traded_company ? 
                        'Yes'
                     : 
                        'No'
                    }
                  </Col>
                  {applicationData?.controls_public_traded_company && (
                <><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Please provide details of the Publicly Traded Company and your involvement with it:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.tradingName}
                    </Col></>
                  )}
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">I confirm I am not employed by a Financial Services Firm:</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.employedfinancialservicefirm==="true" ? 
                        'Confirmed'
                     : 
                        'Not Confirmed'
                    }
                  </Col>
                  {applicationData?.employedfinancialservicefirm=='true' && (
                <>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Compliance Officer Name:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.complianceofficername}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Compliance Officer Contact Number:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.complianceofficercontactnumber}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">Compliance Officer Email Address:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.complianceofficeremail}
                    </Col>
                    </>
                  )}

              <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">I confirm that nobody in my immediate family is employed by a Financial Services Firm:</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.isfamilyemployedfinancialservicefirm===true ? 
                        'Confirmed'
                     : 
                        'Not Confirmed'
                    }
                  </Col>
                  {applicationData?.isfamilyemployedfinancialservicefirm== true && (
                <>
                <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong"> Who Is Employed by the Financial Services Firm:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.whoemployedfinancialservicefirm}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold', }}>
                    <span className="strong">If a Family Member, Provide Relationship:</span>
                  </Col><Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                      {applicationData?.familymemberrealtionship}
                    </Col>
                    </>
                  )}

                {/* // */}
                {/* <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                  Signature
                </Col> */}
                




                {/* // */}
                {applicationData?.accountHolderType === "Joint" && (<>

                  <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                    <span className="strong">Secondary Applicant Details</span>
                  </Col>


                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Title:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointnametitle}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    First Name: &nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointfirstname}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Last Name: &nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointsurname}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Middle Name:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointmiddleName}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Your Email:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.joint_username}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Phone Number:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointmobilenumber}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Marital Status:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointmaritalstatus}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Dependant:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointdependents}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Your Account Type:
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    Joint
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Date of Birth:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointdob}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    House Street Address:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointaddressline1}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Address Line 2:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointaddressline2}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    County:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointstateorprovince}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Town / City:
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointtownorcity}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Country:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.country}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Post Code:
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointpostcode}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Secondary PPS number:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointppsnumber}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                  Secondary Passport Number:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.dependendPassportNumber}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Country Issued:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointissuedcountry}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Passport Expiry Date:&nbsp;
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointpassport_expiry_date}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Country of Birth:&nbsp;</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointcountryofbirth}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Country of Citizenship:&nbsp;</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointcountryofcitizenship}
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    <span className="strong">Are you also a tax resident in another country?</span>
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {!applicationData?.joint_details?.jointistaxresident ? 
                        'Yes'
                     : 
                        'No'
                    }
                  </Col>

                  {/* {applicationData?.joint_details?.jointistaxresident && (
                    <Col md="6" style={{ lineHeight: '2.5' }}>
                      {applicationData?.joint_details?.jointistaxresident}
                    </Col>
                  )} */}

                  {!applicationData?.joint_details?.jointistaxresident && (
                    <>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      <span className="strong">Other Tax Residence Details:</span>
                    </Col>
                 
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointothertaxresidence}
                  </Col>
                  </>                   )}
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                    Employment Status:
                  </Col>
                  <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                    {applicationData?.joint_details?.jointemploymentstatus}
                  </Col>
                  {(applicationData.jointemploymentstatus === 'EMPLOYED' || applicationData.jointemploymentstatus === 'SELFEMPLOYED') && (
                    <>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Occupation:
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {applicationData?.joint_details?.jointoccupation}
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Employer Name:
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {applicationData?.joint_details?.jointemployername}
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Employer Business:
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {applicationData?.joint_details?.jointemployerbusiness}
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Employer Address:
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {applicationData?.joint_details?.jointemployeraddress}
                      </Col>

                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Country:
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: "300" }}>
                        {applicationData?.joint_details?.jointemployerCountry}
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        County:{applicationData?.joint_details?.jointemployerState}
                      </Col>

                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        City:{applicationData?.joint_details?.jointemployeraddress?.city}
                      </Col>

                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Post Code:{applicationData?.joint_details?.jointemployeraddress?.postal_code}
                      </Col>

                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Street:&nbsp;{applicationData?.joint_details?.jointemployeraddress?.street}
                      </Col>

                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Is the Employer a Publicly Listed Company?
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5' }}>
                        {applicationData?.joint_details?.jointisapubliclylistedcompany === true ? 'Yes' : 'No'}
                      </Col>
                      <Col>{applicationData?.joint_details?.jointisapubliclylistedcompany}</Col>
                    </>
                  )}
                  {applicationData.jointisapubliclylistedcompany === true && (
                    <>
                      <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                        Employer Company Stock Ticker Symbol:&nbps;
                      </Col>
                      <Col md="6" style={{ lineHeight: '2.5' }}>
                        {applicationData?.joint_details?.jointemployerCompany}
                      </Col>

                    </>
                  )}

                  <>
                    <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                      Primary Applicant Signature
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Statement of Suitability:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.statement_of_suitability?.primary_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Starlight Smart Reserve PortFolio:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.smart_reserve_portfolio?.primary_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      W8Ben :
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.w8_ben?.primary_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Interactive Broker:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.ibkr_signature?.primary_sign
                      )}
                    </Col>
                    <Col md="12" className="pt-3" style={{ lineHeight: '2.5', fontSize: '25px', fontWeight: 'bold' }}>
                      Secondary Applicant Signature
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Statement of Suitability:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.statement_of_suitability?.joint_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Starlight Smart Reserve PortFolio:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.smart_reserve_portfolio?.joint_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      W8Ben:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.w8_ben?.joint_sign
                      )}
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'bold' }}>
                      Interactive Broker:
                    </Col>
                    <Col md="6" style={{ lineHeight: '2.5', fontSize: 'larger', fontWeight: 'normal' }}>
                      {JSON.stringify(applicationData?.
                        account_signature_response?.ibkr_signature?.joint_sign
                      )}
                    </Col>
                  </>


                </>
                )}

                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleSoSApplication(accountId); }}>Statement of Suitability</a>
                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleSeconddocApplication(accountId); }}>Starlight Smart Reserve Portfolio</a>
                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleW8benPrimaryApplication(accountId); }}>Primary Applicant's W-8BEN </a>
                {applicationData?.accountHolderType === 'Individual' ? null : (
                  <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleW8benSecondaryApplication(accountId); }}>Secondary Applicant's W-8BEN</a>
                )}
                <a href="#" className='new-login-user-id' onClick={(e) => { e.preventDefault(); handleIbApplication(accountId); }}>Interactive Brokers Ireland</a>




              </>
            )}



          </Row>
        </div >
      </div>

    </>
  );
};

export default ViewDocument;


